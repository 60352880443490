import React from "react";
import { Image } from "react-bootstrap";
import notFound from "./not-found.jpg";

function NoDataFoundMessage(props) {

  const defaultLogo = "https://ik.imagekit.io/marketcube/platform/logo/mc-white-logo.png";

  const { logo = defaultLogo, brandName, helpText = "" } = props;

  return (
    <div className="not-found-page">
      {(logo || brandName) &&
        <header className="options d-flex justify-content-between px-3">
          <div className="builder-logo text-white">
            {logo ? (
              <img src={logo} alt={brandName} />
            ) : (
              <strong>{brandName}</strong>
            )}
          </div>
        </header>
      }
      <div className="d-flex justify-content-center flex-column not-found-container align-items-center">
        <Image
          width="400"
          alt="Sorry, Didn't find what you are looking for."
          src={notFound}
        />
        <span className="d-block text-muted">Sorry, Didn't find what you are looking for.</span>
        {
          helpText &&
          <span className="text-muted"> {helpText}</span>
        }
      </div>
    </div >
  );
}

export default NoDataFoundMessage;
