const apiURL = process.env.REACT_APP_API_URL || "http://localhost:3000";
const apikey =
  process.env.REACT_APP_API_KEY || "e7b4e90efa6942798fde1c43c589d75a";

const proxyURL = process.env.REACT_APP_PROXY_URL || "http://localhost:3000";

const brandURL = process.env.REACT_APP_BRAND_URL || "http://localhost:3000";

const clientURL = process.env.REACT_APP_CLIENT_URL || "http://localhost:3000";

const authHeader = {
  Host: "auth",
  apikey,
};

const publicHeader = {
  Host: "public",
  apikey,
};

const emailHeader = {
  Host: "email",
  apikey,
};

const authMiddlewareHeader = {
  Host: "auth-middleware",
  apikey,
};

const config = {
  // urls
  apiURL,
  clientURL,
  proxyURL,
  brandURL,
  // headers
  authHeader,
  authMiddlewareHeader,
  emailHeader,
  publicHeader,
};

export default config;
