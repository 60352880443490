import React from "react";
import { Toast } from "react-bootstrap";
import "./toast.css";

// use it as Toast in other files
const ToastWrapper = (props) => {
  const { toastState, setToastState } = props;

  const {
    toastBody,
    toastClass = "",
    toastHeader,
    autoHide = true,
  } = toastState;
  return (
    <Toast
      onClose={() => setToastState({ active: false })}
      show={toastState.active}
      delay={5000}
      autohide={autoHide}
      className={`fixed-bottom mx-auto bg-dark template-toast rounded-0 ${toastClass} ${
        !toastHeader ? "empty-header" : "drop"
      }`}
    >
      <Toast.Header
        className={`text-center rounded-0 text-white bg-dark ${
          !toastHeader ? "p-0" : "p-3"
        }`}
      >
        <span className="mr-auto">{toastHeader}</span>
      </Toast.Header>

      {toastBody && (
        <Toast.Body className={`text-center ${!toastHeader ? "p-4" : ""}`}>
          {toastBody}
        </Toast.Body>
      )}
    </Toast>
  );
};

export default ToastWrapper;
