import React, { useState, useEffect, useContext } from "react";
import RenderMachine from "../../../lib/components/renderMachine";
import baseHelper from "../../../lib/helper/baseHelper";
import Skeleton from "../../../lib/components/skeleton";
import NoDataFoundMessage from "../../../lib/components/noDataFoundMessage";
import HtmlHead from "../../../lib/components/htmlHead";
import BuilderDataContext from "../../../lib/components/context/builderData";

const Preview = (props) => {
  const [data, setData] = useState({});

  const [hasData, setHasData] = useState();
  const [hasError, setHasError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const { logo, seller, brand } = useContext(BuilderDataContext);


  // Builder preview page favicon for vendor and operator
  const brandIcon =
    (!!logo && logo) ||
    (!!brand && brand.imageUrl) ||
    (!!seller && seller.logo);

  const { brandName, shop } = props.match.params;
  const type = "brand";
  const filter = {
    type,
    shop,
  };

  const dataStates = {
    data,
    setData,
    hasError,
    isLoading,
    setHasData,
    setHasError,
    setIsLoading,
  };

  useEffect(() => {
    baseHelper.getProviderTemplate(dataStates, filter, shop, brandName);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps


  if (isLoading && !hasError)
    return (
      <div className="w-100">
        <Skeleton builder={false} sidebar={false} />
      </div>
    );
  if (hasError)
    return (
      <div className="h-100vh d-flex justify-content-center align-items-center">
        An unexpected error has occurred.
      </div>
    );
  if (!hasData)
    return (
      <div className="d-flex justify-content-center align-items-center w-100 h-100vh">
        <NoDataFoundMessage />
      </div>
    );
  if (hasData)
    return (
      <>
        <HtmlHead title={brandName} favicon={brandIcon} />
        <RenderMachine
          builderClass="drag-n-drop " /* "drag-n-drop draggable" */
          data={data.data}
          type={type}
          match={props.match}
          history={props.history}
          builderPage={false}
        />
      </>
    );
};

export default Preview;
