import axios from "axios";
import components from "mc-components";
import jwtDecode from "jwt-decode";
import config from "../../config";

const {
  apiURL,
  brandURL,
  authHeader,
  emailHeader,
  publicHeader,
} = config;

class BaseHelper {
  constructor() {
    this.token = localStorage.getItem("token");
  }

  /**
   * @desc concat default component properties with user defined
   *
   * @param {Object} props user defined properties
   * @param {Object} defaultProps default props of the component
   *
   * @return {Object} newProps
   */
  allProps(props, defaultProps) {
    let newProps = {};
    const objToArr = Object.entries(props);
    objToArr.forEach(([key, value]) => {
      const props1 = (defaultProps && defaultProps[key]) || {};
      newProps[key] = { ...value, ...props1 };
    });
    newProps = { ...defaultProps, ...newProps };
    return newProps;
  }

  /**
   * @desc to create template data
   *
   * @param {String} shop shop name of the seller
   * @param {String} type type of the page
   *
   * @return {Object}
   */
  async createTemplate(data) {
    try {
      const brandTemplateUrl = `${apiURL}/user/brand/template`;
      const requestParams = {
        data: { ...data },
        headers: authHeader,
        method: "POST",
        uri: brandTemplateUrl,
      };

      const options = this.getRequestOptions(requestParams);
      const response = await axios(options);
      return response;
    } catch (err) {
      return { error: err };
    }
  }

  /**
   * @desc decodes the token
   * @return {Object}
   */
  decodeToken(token = localStorage.getItem("token")) {
    if (token) {
      const decoded = jwtDecode(token);
      return decoded;
    }
    return {};
  }

  isTokenExpired(tokenExp) {
    if (!tokenExp) return true;
    const isExpired = Date.now() / 1000 > tokenExp;
    return isExpired;
  }

  /**
   * @desc get the type specific template from the local storage
   *
   * @param {String} shop shop name of the user
   * @param {String} type type of the page
   *
   * @return {Object}
   */
  async fetchTemplate(dataFilter) {
    try {
      const brandTemplateUrl = `${apiURL}/user/brand/template`;
      const requestParams = {
        data: { ...dataFilter },
        headers: authHeader,
        method: "GET",
        uri: brandTemplateUrl,
      };

      const options = this.getRequestOptions(requestParams);
      const response = await axios(options);
      const trimmedData = this.getTrimmedData(response);
      const formatData = this.formateTemplateData(trimmedData);
      return formatData;
    } catch (err) {
      return { error: "error" };
    }
  }

  /**
   * @desc get the type specific template from the local storage
   *
   * @param {String} key
   *
   * @return {Object} Object
   *
   */
  getFromLS(key) {
    let ls = {};
    if (global.localStorage) {
      try {
        ls = JSON.parse(global.localStorage.getItem("templateData")) || {};
      } catch (e) {
        /*Ignore*/
      }
    }
    return ls[key];
  }

  /**
   * @desc adds condition based classes to the active component
   *
   * @param {Boolean} isActive
   *
   * @return {String} classes
   *
   */
  getBgClassName(isActive) {
    let classes = "add-on-row section mx-0 align-items-md-center ";
    if (isActive) {
      classes += "active-bg";
      return classes;
    }
    return classes;
  }

  /**
   * @desc To get absolute url of image options
   *
   * @param {String} image
   *
   * @return {String} image url
   *
   */
  getImagePath(image) {
    const imagePath = `${this.getRootUrl()}/assets/`;
    if (!image) {
      return imagePath;
    }

    return `${imagePath}${image}`;
  }

  getToken(token) {
    const tokenLS = token || localStorage.getItem("token");
    if (!tokenLS) return false;
    return tokenLS;
  }
  /** get response data
   *
   *  @desc to get trimmed data from the api response
   *  @param {Object} apiResponseData
   * @returns {object} trimmed data
   *
   * **/
  getTrimmedData(apiResponseData) {
    const { data } = apiResponseData || {};
    if (data.status === "ok") {
      return data.data;
    }
  }

  /**
   * @desc To get request options
   *
   * @return options
   *
   */
  getRequestOptions = (requestData) => {
    const { proxyURL } = config;
    const { uri, method, data = "", headers } = requestData;
    const token = localStorage.getItem("token");
    const apiPayload = {
      headers: { ...headers, token },
      json: true,
      method,
      // body: { ...data, filter: data },
      body: data,
      uri: encodeURI(uri),
    };

    // convertedPayload,
    const options = {
      method: "post",
      data: apiPayload,
      url: proxyURL,
    };

    return options;
  };

  /**
   * @desc To get and set data and its state for brand page
   * @param {Object} dataStates states of data
   * @param {Object} filter request params
   *
   */
  async getOperatorTemplate(dataStates, filter, shop) {
    const { setData, setHasData, setHasError, setIsLoading } = dataStates;
    try {
      let templateData = await this.fetchTemplate({ ...filter });
      if (!templateData.data) {
        setHasData(false);
        setHasError(false);
        setIsLoading(false);
        return;
      }
      if (templateData.error) {
        setHasError(true);
        setIsLoading(false);
        return;
      }
      templateData.shop = shop;
      const tempData = [...templateData.data];
      tempData.forEach((component) => {
        const { name, props } = component;
        const defaultProps = (name && components[name].props.props) || {};
        const wholeProps = this.allProps({ ...defaultProps }, { ...props });
        component.props = wholeProps;
        component.cms = components[name].props.cms;
        component.styleType = components[name].props.styleType;
      });
      templateData.data = tempData;
      templateData.sellerId = this.decodeToken()._id;
      setData(templateData);

      setHasData(true);
      setIsLoading(false);
    } catch (err) {
      console.log(err);
      setIsLoading(false);
      setHasError(true);
    }
  }

  async getProviderTemplate(dataStates, filter, shop, brandName) {
    const { setData, setHasData, setHasError, setIsLoading } = dataStates;
    try {
      let templateData = await this.fetchTemplate({ ...filter });
      const { vendorId } = filter;
      if (!templateData.data) {
        setHasData(false);
        setHasError(false);
        setIsLoading(false);
        return;
      }
      if (templateData.error) {
        setHasError(true);
        setIsLoading(false);
        return;
      }
      const vendorData =
        (await this.getVendorTemplate({ shop, brandName, vendorId })) || {};

      const ref = vendorData.data || {};
      if (ref.data) {
        templateData.shop = shop;
        templateData.data = ref.data.data;
        templateData.isBrandTemplateUpdated = ref.data.isBrandTemplateUpdated;
      }
      
      const tempData = [...templateData.data];
      tempData.forEach((component) => {
        const { name, props } = component;
        const defaultProps = (name && components[name].props.props) || {};
        const wholeProps = this.allProps({ ...defaultProps }, { ...props });
        component.props = wholeProps;
        component.cms = components[name].props.cms;
        component.styleType = components[name].props.styleType;
      });
      templateData.data = tempData;
      templateData.vendorId = this.decodeToken()._id;
      setData(templateData);

      setHasData(true);
      setIsLoading(false);
    } catch (err) {
      console.log(err);
      setIsLoading(false);
      setHasError(true);
    }
  }

  updateProps = (property, key, value, setData) => {
    setData((prev) => {
      const newData = { ...prev };
      newData[property][key] = value;
      return newData;
    });
  };

  /**
   * @desc To set query params
   * @param location object
   * @param queryParams object
   *
   * @return query params
   *
   */
  getQueryParams(location, queryParams) {
    let { search } = location;
    const trimmedSearch = search.trim();
    const params = new URLSearchParams(trimmedSearch);
    Object.keys(queryParams).forEach((key) => {
      params.set(key, queryParams[key]);
    });
    search = params.toString();
    return search.replace("%40", "@");
  }

  /**
   * @desc To get root url of current domain
   *
   * @return root url
   *
   */
  getRootUrl() {
    return window.location.origin;
  }

  async getCurrentUser(token) {
    try {
      const uri = `${brandURL}/api/brand/user`;
      const response = await axios.get(uri, {
        headers: { token },
      });

      return response.data;
    } catch (err) {
      console.error("vendor detail error: ", err.message);
    }
  }

  async getVendorDetails(shop, brandName) {
    try {
      const uri = `${apiURL}/public/brand/${shop}/${brandName}`;
      const requestParams = {
        headers: publicHeader,
        method: "GET",
        uri,
      };
      const options = this.getRequestOptions(requestParams);

      const response = await axios(options);
      const ref = response.data.data;
      if (!ref) {
        return {};
      }
      const { user = {}, brandDetail = {}, chatSetting = {}, store= {} } = ref || {};
      const { isHideVendorContact = false } = store || {}; 
      const vendorData = {
        address: user.address,
        brandName: user.brandName,
        city: user.city,
        country: user.country,
        description: brandDetail.description,
        email: user.email.address,
        firstName: user.firstName,
        lastName: user.lastName,
        phoneNumber: user.phoneNumber,
        pinCode: user.pinCode,
        id:user._id,
        isHideVendorContact,
        provinceCode: user.provinceCode
      };
      return {
        brandDetail,
        basicInfo: vendorData,
        chatSetting,
      };
    } catch (err) {
      console.error("vendor detail error: ", err.message);
    }
  }

  async getAssociation() {
    try {
      const uri = `${apiURL}/user/association`;
      const requestParams = { uri, method: "GET", headers: authHeader };
      const options = this.getRequestOptions(requestParams);
      const response = await axios(options);
      return response.data.data;
    } catch (err) {
      console.error("vendor detail error: ", err.message);
    }
  }

  /**
   * @desc To format response data to builder specific format
   *@param {Object} data
   * @return {Object} formattedData
   *
   */
  formateTemplateData(data) {
    if (!data) return false;
    const requiredData = data;
    return requiredData;
  }

  /**
   * @desc To check if valid url
   * @param url
   *
   * @return bool
   *
   */
  isValidURL(url) {
    if (!url) {
      return false;
    }

    // eslint-disable-next-line max-len
    const validURLRegex = /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
    return validURLRegex.test(url);
  }

  
 /**
   * @desc To check if valid email
   * @param email
   *
   * @return bool
   *
   */
  validEmail = (value) =>{
    if (value) {
      const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      const emailVal = new RegExp(emailRegex, 'i')
      return emailVal.test(value);
    }
  }
  
  validName = (value) => {
    if (value) {
      const nameRegex = /^[a-zA-Z]{3}[a-zA-Z ]*$/;
      const nameVal = new RegExp(nameRegex, 'i')
      return nameVal.test(value);
    }
  }
  /**
   * @desc To save brand template to the local storage
   * @param {data} data brand template data
   *
   * @return {null}
   *
   */
  saveToLS(data) {
    global.localStorage.setItem("templateData", JSON.stringify(data));
  }

  /**
   * @desc To get sort array of object in the alphabetically
   * with respect to key is passed.
   * @param {Array} array, target array
   * @param {String} key, a property of an object
   *
   * @return {Array} sorted array
   *
   */
  sort(array, key) {
    const compare = (item1, item2) => {
      let str1 = item1[key] && item1[key];
      let str2 = item2[key] && item2[key];

      if ((typeof item1[key] === typeof item2[key]) === "string") {
        str1 = (item1[key] && item1[key].toLowerCase()) || "";
        str2 = (item2[key] && item2[key].toLowerCase()) || "";
      }
      return (str1 > str2 && 1) || (str1 < str2 ? -1 : 0);
    };
    return array.sort(compare);
  }

  /**
   * @desc updates the template data
   * @param {Object} data data of the template
   *
   * @return {response} response of the end point operation
   *
   */
  async updateTemplate(data) {
    try {
      const brandTemplateUrl = `${apiURL}/user/brand/template`;
      const requestParams = {
        headers: authHeader,
        uri: brandTemplateUrl,
        data,
        method: "PUT",
      };
      const options = this.getRequestOptions(requestParams);
      const response = await axios(options);
      return response;
    } catch (err) {
      return { error: "error" };
    }
  }

  async getVendorTemplate(data) {
    try {
      const vendorTemplateUrl = `${apiURL}/user/brand/vendor-template`;
      const requestParams = {
        headers: authHeader,
        uri: vendorTemplateUrl,
        data: { filter: data },
        method: "GET",
      };

      delete data.data;

      const options = this.getRequestOptions(requestParams);

      const response = await axios(options);
      return response;
    } catch (err) {
      console.log("getVendorTemplate error", err);
      return { error: "error" };
    }
  }

  async updateVendorTemplate(data, slug, brandName) {
    try {
      const vendorTemplateUrl = `${apiURL}/user/brand/vendor-template`;
      const requestParams = {
        headers: authHeader,
        uri: vendorTemplateUrl,
        data: {
          filter: { vendorId: data.vendorId || data.sellerId },
          dataToUpdate: {
            isPublished: data.isPublished,
            publishedAt: data.publishedAt,
            data: data.data,
            shop: data.shop,
            brandSlug: slug,
            brandName,
            isBrandTemplateUpdated: false,
          },
        },
        method: "PUT",
      };

      const options = this.getRequestOptions(requestParams);

      const response = await axios(options);
      return response;
    } catch (err) {
      return { error: "error" };
    }
  }

  async sendMail(data) {
    try {
      const emailAPIEndPoint = `${apiURL}/email`;
      const requestParams = {
        headers: emailHeader,
        uri: emailAPIEndPoint,
        data,
        method: "POST",
      };
      const options = this.getRequestOptions(requestParams);
      const response = await axios(options);
      return response;
    } catch (err) {
      return { error: "error" };
    }
  }

  deepClone(data) {
    return JSON.parse(JSON.stringify(data));
  }
  }
const helper = new BaseHelper();

export default helper;
