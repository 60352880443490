import React, { useState, useEffect, useContext } from "react";
import moment from "moment";
import components from "mc-components";
import CreateOperatorTemplateButton from "../../../lib/components/createProviderTemplateButton";
import RenderMachine from "../../../lib/components/renderMachine/selector";
import BuilderSetting from "../../../lib/components/buiderSetting";
import BrowserAlert from "../../../lib/components/browserAlert";
import Toast from "../../../lib/components/toast";
import baseHelper from "../../../lib/helper/baseHelper";
import BuilderDataContext from "../../../lib/components/context/builderData";
import Skeleton from "../../../lib/components/skeleton";
import SideToolbar from "../../../lib/components/sideToolbar";
import errorBoundary from "../../../lib/components/errorBoundary";
import HtmlHead from "../../../lib/components/htmlHead";
import "./common.css";

const Builder = (props) => {
  const currentUserContext = useContext(BuilderDataContext);

  const [data, setData] = useState({});
  const [hasData, setHasData] = useState();
  const [hasError, setHasError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [activeComponentData, setActiveComponentData] = useState(false);
  const [newItem, setNewItem] = useState(null);
  const [isPageBuilder, setPageBuilder] = useState(true);
  const [toastState, setToastState] = useState({ active: false });
  const [supportedBrowser, setSupportedBrowser] = useState(true);
  const [state, setState] = useState(false);
  const { shop, brandName, logo } = currentUserContext;

  const [componentExist, setComponentExist] = useState();

  const type = "brand";
  const name = "oneColumnLayout";

  const dataStates = {
    data,
    hasData,
    setData,
    hasError,
    isLoading,
    setState,
    setHasData,
    setHasError,
    setIsLoading,
  };
  const filter = { type, shop };

  useEffect(() => {
    onAddItem(newItem);
  }, [newItem]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (navigator.userAgent.indexOf("Chrome") !== -1) {
      baseHelper.getOperatorTemplate(dataStates, filter, shop);
    } else {
      setSupportedBrowser(false);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const name = (name) => name.name === "bannerWithCard";
    const filterData = !!data.data && data.data.some(name);
    const isComponent =  data.data && data.data.length && data.data.some((item=> item.name === "productList"))
    setComponentExist(isComponent)
    setState(filterData);
  }, [data]);

  const moveComponent = (index, moveBy) => {
    setActiveComponentData(false);
    const newData = JSON.parse(JSON.stringify(data));
    const chunk = moveBy === 1 ? index + 1 : index - 1;
    const temp = baseHelper.deepClone(newData.data[index]);
    newData.data[index] = { ...newData.data[chunk] };
    newData.data[chunk] = temp;
    setData(newData);
  };

  const onAddItem = (newElement) => {
    try {
      let { droppedAt, name } = newElement || {};
      if (!(droppedAt && name)) return;

      const newItem = {
        name,
        props: baseHelper.deepClone(components[name].props.props),
        cms: baseHelper.deepClone(components[name].props.cms),
        styleType: baseHelper.deepClone(components[name].props.styleType),
      };

      const newData = baseHelper.deepClone(data);
      const componentData = baseHelper.deepClone(data.data);

      if (name === "header") {
        if (componentData[0].name === name) {
          componentData[0] = newItem;
          newData.data = componentData;
        } else {
          componentData.unshift(newItem);
        }
        newData.data = componentData;
        setData(newData);
        return;
      }

      const position = droppedAt.split("_")[0];

      const updateData = (newData, offset, droppedAt) => {
        const chunk = parseInt(droppedAt.split("_")[1], 10) + offset;
        const array1 = componentData.slice(0, chunk);
        const array2 = componentData.slice(chunk, chunk + componentData.length);
        array1.push(newItem);
        newData.data = [...array1, ...array2];
        setData(newData);
        setToastState({ active: false });
        setPageBuilder(true);
      };
      if (name === "bannerWithCard") {
        const chunk = 1 - parseInt(droppedAt.split("_")[1], 10);
        updateData(newData, chunk, droppedAt, setData);
      } else if (position === "empty") {
        updateData(newData, 1, droppedAt, setData);
      } else {
        setPageBuilder(false);
        const toastHeader = <span>Choose a drop location</span>;
        const toastBody = (
          <div className="toast-add">
            <button
              className="mb-2"
              onClick={() => {
                updateData(newData, 0, droppedAt);
              }}
            >
              Add to the top of {droppedAt.split("_")[0]}
            </button>

            <button
              className="mb-2"
              onClick={() => {
                const index = parseInt(droppedAt.split("_")[1], 10);
                componentData[index] = newItem;
                newData.data = componentData;
                newData.data = componentData;
                setData(newData);
                setToastState({ active: false });
                setPageBuilder(true);
              }}
            >
              Replace with {droppedAt.split("_")[0]}
            </button>
            <button
              onClick={() => {
                updateData(newData, 1, droppedAt);
              }}
            >
              Add to the bottom of {droppedAt.split("_")[0]}
            </button>
          </div>
        );

        setToastState({
          toastHeader,
          toastBody,
          active: true,
          autoHide: false,
        });
      }
      setNewItem({});
      setActiveComponentData(false);
    } catch (err) {
      setNewItem({});
      console.log("error: ", err);
    }
  };

  const sideToolbarProps = {
    activeComponentData,
    data,
    state,
    newItem,
    setData,
    setNewItem,
    type,
    componentExist
  };
  const toastProps = {
    toastState,
    setToastState,
  };

  const removeElement = (index) => {
    const newData = JSON.parse(JSON.stringify(data));
    newData.data.splice(index, 1);
    setData({ ...newData });
    setActiveComponentData(false);
  };

  const updateProps = (property, key, value, setData) => {
    const { index } = activeComponentData;
    setData((prev) => {
      const newData = { ...prev };
      newData.data[index].props[property][key] = value;
      return newData;
    });
  };

  if (!supportedBrowser) {
    return (
      <div>
        <BrowserAlert />
      </div>
    );
  }

  const contextData = {
    ...currentUserContext,
    activeComponentData,
    data,
    history: props.history,
    match: props.match,
    setData,
    updateProps,
  };

  return (
    <>
      <HtmlHead title={shop} favicon={logo || ""} />
      <BuilderDataContext.Provider value={contextData}>
        <header className="options d-flex justify-content-between px-3">
          <div className="builder-logo text-white">
            {logo ? (
              <img src={logo} alt={brandName} />
            ) : (
              <strong>{brandName}</strong>
            )}
          </div>
          <Toast toastClass="drop-component" {...toastProps} />
          <div className="control-nav d-flex">
            {data.publishedAt && (
              <span className="last-updated-label d-flex justify-content-center align-items-center mr-2 px-3">
                Last published:{" "}
                {JSON.parse(JSON.stringify(moment(data.publishedAt).fromNow()))}
              </span>
            )}
            <span className="last-updated-label d-flex justify-content-center align-items-center mr-5 px-3">
              Last updated:
              {JSON.parse(JSON.stringify(moment(data.updatedAt).fromNow()))}
            </span>
            <BuilderSetting
              history={props.history}
              brandName={brandName}
              type={type}
              name={name}
              shop={shop}
              setPageBuilder={setPageBuilder}
              dataStates={dataStates}
              toastProps={toastProps}
            />
          </div>
        </header>

        {isLoading && !hasError && (
          <div className="w-100">
            <Skeleton body={true} />
          </div>
        )}
        {hasError && (
          <div className="h-100vh d-flex justify-content-center align-items-center">
            An unexpected error has occurred.
          </div>
        )}
        {!hasData && (
          <div className="d-flex justify-content-center align-items-center w-100 h-100vh">
            <span className="mb-2">No data found</span>
            <CreateOperatorTemplateButton />
          </div>
        )}
        {hasData && (
          <div className="mx-0 operator builder">
            <SideToolbar props={sideToolbarProps} className={"sideBarShow"} />
            <RenderMachine
              builderClass="drag-n-drop " /* "drag-n-drop draggable" */
              data={data.data}
              dropZoneClass="drop-zone"
              type={type}
              removeElement={removeElement}
              setActiveComponentData={setActiveComponentData}
              match={props.match}
              history={props.history}
              builderPage={isPageBuilder}
              moveComponent={moveComponent}
            />
          </div>
        )}
      </BuilderDataContext.Provider>
    </>
  );
};

export default errorBoundary(Builder);
