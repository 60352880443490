import React, { useState, useEffect, useContext } from "react";
import moment from "moment";
import RenderMachine from "../../../lib/components/renderMachine";
import BuilderSetting from "../../../lib/components/buiderSetting";
import BrowserAlert from "../../../lib/components/browserAlert";
import Toast from "../../../lib/components/toast";
import baseHelper from "../../../lib/helper/baseHelper";
import BuilderDataContext from "../../../lib/components/context/builderData";
import Skeleton from "../../../lib/components/skeleton";
import SideToolbar from "../../../lib/components/sideToolbar";
import errorBoundary from "../../../lib/components/errorBoundary";
import NoDataFoundMessage from "../../../lib/components/noDataFoundMessage";
import HtmlHead from "../../../lib/components/htmlHead";

// Note :-  commented code is useful for phase - 2 release

// import ToastModal from "../../../lib/components/toastModal";

import "./common.css";

const Builder = (props) => {
  const currentUserContext = useContext(BuilderDataContext);

  const [data, setData] = useState({});
  const [hasData, setHasData] = useState();
  const [hasError, setHasError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [activeComponentData, setActiveComponentData] = useState(false);
  const [isPageBuilder, setPageBuilder] = useState(true);
  const [toastState, setToastState] = useState({ active: false });
  const [supportedBrowser, setSupportedBrowser] = useState(true);
  const { seller, brandName, _id, slug } = currentUserContext;
  const { shop, logo } = seller;

  const type = "brand";
  const name = "oneColumnLayout";

  const dataStates = {
    data,
    hasData,
    setData,
    hasError,
    isLoading,
    setHasData,
    setHasError,
    setIsLoading,
  };
  const filter = { type, shop, vendorId: _id };

  useEffect(() => {
    baseHelper.getProviderTemplate(dataStates, filter, shop, brandName);
  }, []); // eslint-disable-next-line react-hooks/exhaustive-deps

  const sideToolbarProps = {
    activeComponentData,
    data,
    setData,
    type,
  };
  const toastProps = {
    toastState,
    setToastState,
  };

  const updateProps = (property, key, value, setData) => {
    const { index } = activeComponentData;
    setData((prev) => {
      const newData = { ...prev };
      newData.data[index].props[property][key] = value;
      return newData;
    });
  };
  if (!supportedBrowser) {
    return (
      <div>
        <BrowserAlert />
      </div>
    );
  }

  const contextData = {
    ...currentUserContext,
    activeComponentData,
    data,
    history: props.history,
    match: props.match,
    setData,
    updateProps,
  };

  const { isBrandTemplateUpdated } = data;

  return (
    <>
      <HtmlHead title={shop} favicon={logo || ""} />
      <BuilderDataContext.Provider value={contextData}>
        <header
          className={`options ${!!isBrandTemplateUpdated ? "provider-header" : ""
            }`}
        >
          <div className="d-flex justify-content-between px-3">
            <div className="builder-logo text-white">
              {logo ? <img src={logo} alt={shop} /> : <strong>{shop}</strong>}
            </div>
            <Toast {...toastProps} />
            <div className="control-nav d-flex align-items-center">
              {data.publishedAt && (
                <span className="last-updated-label d-flex justify-content-center align-items-center mr-2 px-3">
                  Last published:{" "}
                  {JSON.parse(
                    JSON.stringify(moment(data.publishedAt).fromNow())
                  )}
                </span>
              )}
              <span className="last-updated-label d-flex justify-content-center align-items-center mr-5 px-3">
                Last updated:
                {JSON.parse(JSON.stringify(moment(data.updatedAt).fromNow()))}
              </span>
              <BuilderSetting
                history={props.history}
                brandName={brandName}
                slug={slug}
                type={type}
                name={name}
                shop={shop}
                setPageBuilder={setPageBuilder}
                dataStates={dataStates}
                toastProps={toastProps}
                isBrandTemplateUpdated={isBrandTemplateUpdated}
              />
              {/* <button
            onClick={toggleToastModal}
            className="btn global-button notification-icon border-0 position-relative"
            disabled={!isVerified}
          >
            {isVerified && (
              <span className="active-notification position-absolute"></span>
            )}
            <i className="fa fa-bell-o" aria-hidden="true"></i>
          </button> */}
              {/* <ToastModal
            show={show}
            headerTitle="Template verification"
            toggleToastModal={toggleToastModal}
            buttonTitle="Preview"
          >
            Please verify your template by your seller
          </ToastModal> */}
            </div>
          </div>
        </header>

        {/* <div className="secondary-header"> Component </div> */}
        {isLoading && !hasError && (
          <div className="w-100">
            <Skeleton body={true} />
          </div>
        )}
        {hasError && (
          <div className="h-100vh d-flex justify-content-center align-items-center">
            An unexpected error has occurred.
          </div>
        )}
        {!hasData && (
          <div className="d-flex justify-content-center align-items-center w-100 h-100vh">
            <NoDataFoundMessage brandName={brandName || shop} logo={logo} helpText="Please contact your operator" />
          </div>
        )}
        {hasData && (
          <div
            className={`mx-0 provider builder ${isBrandTemplateUpdated ? "template-updated" : ""
              }`}
          >
            <SideToolbar props={sideToolbarProps} className={"sideBarShow"} />
            <RenderMachine
              builderClass="drag-n-drop " /* "drag-n-drop draggable" */
              data={data.data}
              dropZoneClass="drop-zone"
              type={type}
              setActiveComponentData={setActiveComponentData}
              match={props.match}
              history={props.history}
              builderPage={isPageBuilder}
            />
          </div>
        )}
      </BuilderDataContext.Provider>
    </>
  );
};

export default errorBoundary(Builder);
