import React from "react";

import { Switch, Route, BrowserRouter as Router } from "react-router-dom";

import errorBoundary from "./lib/components/errorBoundary";

import publicRoutes from "./routes/public";
import privateRoutes from "./routes/private";

import PublicLayout from "./layout/public";
import PrivateLayout from "./layout/private";

import NoDataFoundMessage from "./lib/components/noDataFoundMessage";


function App() {
  return (
    <main className="App main-wrapper">
      <Router>
        <Switch>
          {publicRoutes.map((route, key) => {
            const { component, path } = route;
            return (
              <Route
                exact
                path={path}
                key={key}
                render={(route) => (
                  <PublicLayout route={route} component={component} />
                )}
              />
            );
          })}
          {privateRoutes.map((route, key) => {
            const { component, path } = route;
            return (
              <Route
                exact
                path={path}
                key={key}
                render={(route) => (
                  <PrivateLayout route={route} component={component} />
                )}
              />
            );
          })}
          <Route path="*" exact={true} component={NoDataFoundMessage} />
        </Switch>
      </Router>
    </main>
  );
}
export default errorBoundary(App);
