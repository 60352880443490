import React, { useState, useEffect } from "react";
import helper from "../lib/helper/baseHelper";
import CurrentUserContext from "../lib/components/context/builderData";
import { Container, Row } from "react-bootstrap";
import Skeleton from "./../lib/components/skeleton";
import NoDataFoundMessage from "../lib/components/noDataFoundMessage";

const PrivateLayout = (props) => {
  const { component: Component, route } = props;

  const [currentUser, setCurrentUser] = useState(null);

  const [vendorData, setVendorData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [hasInvalidData, setHasInvalidData] = useState(false);
  const { token } = route.match.params;
  localStorage.setItem("token", token);

  const getPageData = async () => {
    try {
      if (!token) {
        return;
      }

      const decodedToken = helper.decodeToken(token);
      const tokenExpired = !helper.isTokenExpired(decodedToken.exp);
      if (tokenExpired) {
        setCurrentUser("tokenExpired");
        return;
      }

      const { data } = await helper.getCurrentUser(token);
      const { seller, role, vendor } = data;
      let user = seller;

      if (role === "vendor") {
        vendor.seller = seller;
        user = vendor;
      }
      const response =
        (await helper.getVendorDetails(
          seller.shop,
          (vendor && vendor.brandName) || (seller && seller.brandName)
        )) || {};

      setCurrentUser(user);
      setVendorData(response);
      setIsLoading(false);
    } catch (error) {
      console.log(("error", error));
      setHasInvalidData(true);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getPageData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (isLoading) {
    const skeletonProps = {};
    const condition = /preview/.test(route.location.pathname);
    if (condition) {
      skeletonProps.builder = false;
      skeletonProps.sidebar = false;
    }
    return (
      <div>
        <Skeleton {...skeletonProps} />
      </div>
    );
  }

  if (hasInvalidData) return <NoDataFoundMessage />;

  if (currentUser === "tokenExpired")
    return (
      <h1
        style={{ height: "100vh" }}
        className="font-weight-bolder d-flex justify-content-center align-content-center align-items-center"
      >
        Token expired
      </h1>
    );

  if (!currentUser)
    return (
      <h1
        style={{ height: "100vh" }}
        className="font-weight-bolder d-flex justify-content-center align-content-center align-items-center"
      >
        Token not found
      </h1>
    );

  const context = {
    ...props.route,
    ...currentUser,
    vendorData: { ...vendorData },
  };

  return (
    <CurrentUserContext.Provider value={context}>
      <Container fluid>
        <Row>
          <Component {...props.route} />
        </Row>
      </Container>
    </CurrentUserContext.Provider>
  );
};

export default PrivateLayout;
