import React, { useState, useContext } from "react";
import { Button } from "react-bootstrap";
import GrowSpinner from "../growSpinner";
import contextValue from "../context/builderData";
import Toast from "../toast";
import baseHelper from "../../helper/baseHelper";

const CreateProviderTemplateButton = (props) => {
  const [creating, setCreating] = useState(false);
  const [toastState, setToastState] = useState({ active: false });

  const { history, shop, _id } = useContext(contextValue);
  const { template = "oneColumnLayout", pageName = "brand" } = props;
  const getButtonContent = () => {
    let content = "Create template";
    if (creating) content = <GrowSpinner text={"Creating..."} />;
    return content;
  };

  const onClick = async () => {
    setCreating(true);
    const { data } = await baseHelper.fetchTemplate({
      type: pageName,
      name: template,
    });

    const templateData = {
      data,
      name: template,
      shop,
      type: pageName,
      sellerId: _id,
    };

    try {
      const response = await baseHelper.createTemplate(templateData);

      setCreating(false);
      if (!response.data) {
        setToastState({
          active: true,
          toastClass: "alert-danger",
          toastBody:
            "Template creation failed. Please make sure that there is no duplicate template.",
        });
        return;
      }
      window.location.reload(false);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Toast toastState={toastState} setToastState={setToastState} />
      <Button className="global-button secondary" onClick={() => onClick()}>
        {getButtonContent()}
      </Button>
    </>
  );
};
export default CreateProviderTemplateButton;
