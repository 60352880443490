import React from "react";
import { Helmet } from "react-helmet";
import defaultFavicon from "./fevicon.png";

const HtmlHead = (props) => {
  const { favicon = {}, title = {} } = props;

  return (
    <Helmet>
      <title>{title}</title>
      <link
        id="favicon"
        rel="icon"
        href={favicon || defaultFavicon}
        type="image/x-icon"
      />
    </Helmet>
  );
};

export default HtmlHead;
