import React, { useState, useEffect } from "react";
import GetNamedComponent from "./getNamedComponent";
import OneColumnTemplate from "../oneColumnTemplate";
import "./renderMachine.css";

export default function RenderMachine(props) {
  const {
    builderPage,
    history,
    match,
    pageName,
    builderClass = "",
    data = {},
    setActiveComponentData,
  } = props;

  const [active, setActive] = useState({});

  const handleTemplateClick = (nameIndex, componentData) => {
    setActive({ [nameIndex]: "activeTemplate" });
    setActiveComponentData({
      index: parseInt(nameIndex.split("_")[1], 10),
      componentData,
      pageName,
    });
  };

  const innerHTML = (name, index, componentData = {}) => {
    const nameIndex = `${name}_${index}`;
    const getNamedComponentProps = {
      props: componentData.props,
      name,
      history,
      match,
    };
    const { media } = componentData.cms;
    const nonCustomizable = !Object.entries(media).length;
    if (!builderPage) return <GetNamedComponent {...getNamedComponentProps} />;
    return (
      <div key={nameIndex} className={`${builderClass} ${active[nameIndex]}`}>
        <div className="edit-options">
          <button
            className="edit-btn"
            disabled={nonCustomizable}
            onClick={() => handleTemplateClick(nameIndex, componentData)}
          >
            <i className="fa fa-cog" aria-hidden="true"></i>
          </button>
        </div>

        <GetNamedComponent
          {...getNamedComponentProps}
          active={Object.keys(active)[0]}
          index={index}
        />
      </div>
    );
  };
  const renderComponent = (name, index, componentData) => {
    return innerHTML(name, index, componentData);
  };

  const layout = (
    <OneColumnTemplate data={data} renderComponent={renderComponent} />
  );

  useEffect(() => {
    if (builderPage) {
      setActive({ [`${data[0].name}_0`]: "activeTemplate" });
      setActiveComponentData({
        index: 0,
        componentData: data[0],
        pageName,
      });
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="w-100 template" style={{ padding: 0 }}>
      {layout}
    </div>
  );
}
