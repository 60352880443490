import React, { useContext } from "react";
import { useAccordionToggle, AccordionContext } from "react-bootstrap";

function CustomAccordionToggle({ children, eventKey, callback }) {
  const currentEventKey = useContext(AccordionContext);

  const decoratedOnClick = useAccordionToggle(
    eventKey,
    () => callback && callback(eventKey)
  );

  const isCurrentEventKey = currentEventKey === eventKey;

  return (
    <span
      className={`d-block text-capitalize position-relative ${
        isCurrentEventKey ? "open" : ""
      }`}
      onClick={decoratedOnClick}
    >
      {children}
    </span>
  );
}
export default CustomAccordionToggle;
