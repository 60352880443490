import React, { useContext } from "react";
import {
  BasicBanner,
  BannerWithCard,
  BasicCard,
  BasicForm,
  BasicSlider,
  Footer,
  FancyFooter,
  GridForm,
  Header,
  SmartHeader,
  PortraitVideo,
  InformationCard,
  ProductList,
  VideoCard,
} from "mc-components";
import BuilderContext from "../../components/context/builderData";
import config from "../../../config";
import helper from "../../helper/baseHelper";


const GetNamedComponent = (data) => {
  const builderContext = useContext(BuilderContext);

  const { props, name } = data || {};
  const { seller, brandName, roles, vendorData = {} } = builderContext;
  const { basicInfo = {} } = vendorData;
  const { id } = basicInfo; 
  const { shop } = seller || builderContext;

  const onSubmitCallback = async (data) => {
    const newData = {
      fullname: data.fullName,
      email: data.email,
      senderEmail: data.senderEmail,
      number: data.number,
      city: data.city,
      message: data.message,
      code: "brandContactForm",
      label: "brandContactForm",
      receiverId: id
    };
    await helper.sendMail(newData);
  };

  const role =
    (roles && roles.name) || (seller && seller.roles && seller.roles.name);

  props.updateProps = builderContext.updateProps;
  props.setData = builderContext.setData;

  if (data.active) {
    props.isEditMode = data.index === parseInt(data.active.split("_")[1], 10);
  }

  let returnElement = <></>;

  if (name) {
    switch (name) {
      case "basicBanner": {
        returnElement = <BasicBanner {...props} />;
        break;
      }
      case "bannerWithCard": {
        const allProps = helper.deepClone(props);

        allProps.content = helper.deepClone({
          ...props.content,
          ...builderContext.vendorData.basicInfo,
        });
        allProps.brandDetail = helper.deepClone({
          ...builderContext.vendorData.brandDetail,
        });
        returnElement = <BannerWithCard {...allProps} />;
        break;
      }
      case "basicCard": {
        returnElement = <BasicCard {...props} />;
        break;
      }
      case "basicForm": {
        returnElement = (
          <BasicForm {...props} onSubmitCallback={onSubmitCallback} />
        );
        break;
      }
      case "basicSlider": {
        returnElement = <BasicSlider {...props} />;
        break;
      }

      case "footer": {
        returnElement = <Footer {...props} />;
        break;
      }
      case "fancyFooter": {
        returnElement = <FancyFooter {...props} />;
        break;
      }

      case "gridForm": {
        returnElement = (
          <GridForm {...props} onSubmitCallback={onSubmitCallback} />
        );
        break;
      }

      case "header": {
        returnElement = <Header {...props} />;
        break;
      }
      case "smartHeader": {
        returnElement = <SmartHeader {...props} />;
        break;
      }
      case "informationCard": {
        returnElement = <InformationCard {...props} />;
        break;
      }
      case "portraitVideo": {
        returnElement = <PortraitVideo {...props} />;
        break;
      }

      case "productList": {
        returnElement = (
          <ProductList
            {...props}
            match={data.match}
            history={data.history}
            config={config}
            brandName={brandName}
            shop={shop}
            role={role} // Page builder:  role using for showing default products on seller end
          />
        );
        break;
      }

      case "videoCard": {
        returnElement = <VideoCard {...props} />;
        break;
      }
      default:
        returnElement = false;
        break;
    }
  }
  return <React.Fragment>{returnElement}</React.Fragment>;
};

export default GetNamedComponent;
