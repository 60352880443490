import React, { useContext } from "react";
import currentUserContext from "../../../lib/components/context/builderData";
import Operator from "./operator";
import Provider from "./provider";

const Selector = (props) => {
  const context = useContext(currentUserContext);
  const Component = context.roles.name === "seller" ? Operator : Provider;
  return <Component {...props} />;
};

export default Selector;
