import React from "react";
import { Modal, Button } from "react-bootstrap";

function CommonModal(props) {
  const {
    headerTitle,
    children,
    actionButton,
    show,
    getTemplate,
    handleClose,
    cancelButton,
    className,
    top,
  } = props;

  return (
    <Modal style={{ top }} show={show} onHide={handleClose}>
      {!!headerTitle && (
        <Modal.Header closeButton>
          <Modal.Title>{headerTitle}</Modal.Title>
        </Modal.Header>
      )}

      <Modal.Body className={className}>{children}</Modal.Body>
      <Modal.Footer
        className={`py-1 ${!!cancelButton ? "justify-content-between" : ""}`}
      >
        {!!cancelButton && (
          <Button variant="outline-danger" onClick={handleClose}>
            {cancelButton}
          </Button>
        )}
        <Button className="global-button secondary" onClick={getTemplate}>
          {actionButton}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default CommonModal;
