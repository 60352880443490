import React, { useState, useEffect } from "react";
import { Accordion, Card, Tab, Tabs } from "react-bootstrap";
import VanillaDragDrop from "../vanillaDragDrop/vanillaDragDrop";
import baseHelper from "../../helper/baseHelper";
import ColorPalet from "../colorPalet";
import Select from "../select";
import TextField from "../textField";
import "./sidebar.css";
import components from "mc-components";
import CustomAccordionToggle from "./customAccordionToggle";

const groupedData = () => {
  const ele = {};
  const unsorted = Object.values(components);
  // const sorted = baseHelper.sort(unsorted, "groupId");
  unsorted.forEach(({ name, groupId, thumbnail }) => {
    const data = {
      value: name,
      label: name,
      thumbnail,
      groupId,
    };
    if (!ele[groupId]) {
      ele[groupId] = [];
    }
    ele[groupId].push(data);
  });
  return Object.entries(ele);
};

const DragAbleComponent = (props) => {
  const { label, value, thumbnail } = props.object || {};
  const noThumbnail =
    "https://ik.imagekit.io/marketcube/platform/storybook/no-image.png";
  return (
    <div className="thumbnail" data-value={value}>
      <img src={thumbnail ? thumbnail : noThumbnail} alt={label} />
      <span className="label">{label}</span>
      <span className="label disabled">Allowed only once in template</span>
    </div>
  );
};

const SideToolbar = (props) => {
  const [panelIndex, setPanelIndex] = useState();
  const [key, setKey] = useState("components");

  const {
    data: globalData,
    setData,
    activeComponentData,
    setNewItem,
    state,
    componentExist
  } = props.props;
  const { index, componentData = {} } = activeComponentData || {};
  const [droppableComponent] = useState(groupedData());
  const [properties, setProperties] = useState(componentData.props || {});
  const [cms, setCMS] = useState(componentData.cms || {});
  const [styleType, setStyleType] = useState(componentData.styleType || {});

  useEffect(() => {
    if (activeComponentData) {
      setKey("design");
    } else {
      setKey("components");
    }
  }, [activeComponentData]);

  useEffect(() => {
    setProperties(componentData.props || {});
    setCMS(componentData.cms || {});
    setStyleType(componentData.styleType || {});
  }, [activeComponentData]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    baseHelper.saveToLS(globalData);
  }, [globalData]);

  const updateData = (property, subProperty, value) => {
    try {
      const state = { ...properties };
      state[property][subProperty] = value;
      const dataState = { ...globalData };
      dataState.data[index].props = properties;
      setData(dataState);
    } catch (err) {
      console.log("updateData: ", err);
    }
  };

  const getProperties = () => {
    const { style = {}, media = {}, links = {} } = properties;
    const nonCustomizable =
      !Object.entries(style).length &&
      !Object.entries(media).length &&
      !Object.entries(links).length;
    return (
      <>
        <div className="editableProps">
          <Tabs defaultActiveKey="style" id="uncontrolled-tab-example">
            {!!Object.entries(style).length && (
              <Tab eventKey="style" title="Style">
                {Object.entries(style).map(([key]) => (
                  <div
                    key={key}
                    className="toolBox-properties style position-relative"
                  >
                    <label className="mb-0">{cms.style[key]}</label>

                    {styleType.style[key] && styleType.style[key].type === "select" && (
                      <Select
                        value={properties.style[key]}
                        options={styleType.style[key].options}
                        updateData={updateData}
                        keyProperty={key}
                        property="style"
                      />
                    )}
                    {styleType.style[key] && styleType.style[key].type === "textField" && (
                      <TextField
                        value={properties.style[key]}
                        setValue={(e) =>
                          updateData("style", key, e.target.value)
                        }
                      />
                    )}
                    {styleType.style[key] && styleType.style[key].type === "color" && (
                      <ColorPalet
                        color={properties.style[key]}
                        updateData={updateData}
                        keyProperty={key}
                        property="style"
                      />
                    )}
                  </div>
                ))}
              </Tab>
            )}

            {!!Object.entries(media).length && (
              <Tab eventKey="media" title="Media">
                {Object.entries(media).map(([key]) => (
                  <div key={key} className="toolBox-properties">
                    <label>{cms.media[key]}:</label>
                    <input
                      type="text"
                      value={properties.media[key]}
                      onChange={(e) => {
                        updateData("media", key, e.target.value);
                      }}
                    />
                  </div>
                ))}
              </Tab>
            )}
            {/* {!!Object.entries(links).length && (
              <Tab eventKey="links" title="Links">
                {Object.entries(links).map(([key]) => (
                  <div key={key} className="test-component toolBox-properties">
                    <label>{cms.links[key]}:</label>
                    <input
                      type="text"
                      value={properties.links[key]}
                      onChange={(e) => {
                        updateData("links", key, e.target.value);
                      }}
                    />
                  </div>
                ))}
              </Tab>
            )} */}

            {nonCustomizable && (
              <Tab
                eventKey="disabled"
                tabClassName="w-100 px-0"
                disabled
                title={
                  <strong className="alert alert-warning mb-2 d-block">
                    No option available to customize
                  </strong>
                }
              ></Tab>
            )}
          </Tabs>
        </div>
        {/* <div className="editableProps">
          <span>Content</span>
          {Object.entries(content).map(([key]) => (
            <div key={key} className="toolBox-properties">
              <label>{cms.content[key]}:</label>
              <input
                type="text"
                value={properties.content[key]}
                onChange={(e) => {
                  updateData("content", key, e.target.value);
                }}
              />
            </div>
          ))}
        </div> */}
      </>
    );
  };

  return (
    <aside id="sideBar" className={props.className}>
      <Tabs
        activeKey={key}
        onSelect={(k) => setKey(k)}
        id="main-tab"
        className="main-tabs"
      >
        <Tab eventKey="components" title="Components">
          <Accordion defaultActiveKey={1}>
            {droppableComponent.map(([groupId, component], index) => {
              return (
                <Card className={`border-0 accordion-${groupId}`}>
                  {/*  
                 never add key, it will break drag functionality
                 key={groupId + Math.random() * 1000} */}

                  <Accordion.Toggle
                    as={Card.Header}
                    eventKey={index + 1}
                    className="p-0 mb-0"
                  >
                    <CustomAccordionToggle
                      setPanelIndex={setPanelIndex}
                      panelIndex={index + 1}
                      eventKey={index + 1}
                    >
                      {groupId}
                    </CustomAccordionToggle>
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey={index + 1}>
                    <Card.Body className="py-2">
                      <VanillaDragDrop
                        setNewItem={setNewItem}
                        state={state}
                        componentExist={componentExist}
                        name={groupId}
                        dropZoneClasses="toolbox-items"
                      >
                        {component.map((object) => (
                          <DragAbleComponent
                            /* 
                       never add key, it will break drag functionality  
                       key={object.value + Math.random()} */
                            object={object}
                          />
                        ))}
                      </VanillaDragDrop>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              );
            })}
          </Accordion>
        </Tab>

        <Tab
          eventKey="design"
          title="Design"
          disabled={!Object.entries(properties).length ? "disabled" : ""}
          className={!Object.entries(properties).length ? "disabled" : ""}
        >
          {getProperties()}
          {getProperties().length === 0 && "Select a component to edit"}
        </Tab>
      </Tabs>
    </aside>
  );
};

export default SideToolbar;
