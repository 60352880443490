import React, { useState, useEffect } from "react";
import { Tab, Tabs } from "react-bootstrap";
import baseHelper from "../../helper/baseHelper";
import ColorPalet from "../colorPalet";
import Select from "../select";
import "./sidebar.css";

const SideToolbar = (props) => {
  const { data: globalData, setData, activeComponentData } = props.props;
  const { index, componentData = {} } = activeComponentData || {};
  const [properties, setProperties] = useState(componentData.props || {});
  const [cms, setCMS] = useState(componentData.cms || {});
  const [styleType, setStyleType] = useState(componentData.styleType || {});

  useEffect(() => {
    baseHelper.saveToLS(globalData);
  }, [globalData]);

  useEffect(() => {
    setProperties(componentData.props || {});
    setCMS(componentData.cms || {});
    setStyleType(componentData.styleType || {});
  }, [activeComponentData]); // eslint-disable-next-line react-hooks/exhaustive-deps

  const updateData = (property, subProperty, value) => {
    try {
      const state = { ...properties };
      state[property][subProperty] = value;
      const dataState = { ...globalData };
      dataState.data[index].props = properties;
      setData(dataState);
    } catch (err) {
      console.log("updateData: ", err);
    }
  };

  const getProperties = () => {
    const { style = {}, media = {}, links = {} } = properties;
    const nonCustomizable =
      !Object.entries(media).length && !Object.entries(links).length;

    return (
      <>
        <div className="editableProps">
          <Tabs defaultActiveKey="media" id="uncontrolled-tab-example">
            <Tab eventKey="style" title="Style">
              {Object.entries(style).map(([key]) => (
                <div key={key} className="toolBox-properties style">
                  <label className="mb-0">{cms.style[key]}</label>
                  {styleType.style[key] && styleType.style[key].type === "select" && (
                    <Select
                      value={properties.style[key]}
                      options={styleType.style[key].options}
                      updateData={updateData}
                      keyProperty={key}
                      property="style"
                    />
                  )}
                  {styleType.style[key] && styleType.style[key].type === "color" && (
                    <ColorPalet
                      color={properties.style[key]}
                      updateData={updateData}
                      keyProperty={key}
                      property="style"
                    />
                  )}
                </div>
              ))}
            </Tab>
            {!!Object.entries(media).length && (
              <Tab eventKey="media" title="Media">
                {Object.entries(media).map(([key]) => (
                  <div key={key} className="toolBox-properties">
                    <label>{cms.media[key]}:</label>
                    <input
                      type="text"
                      value={properties.media[key]}
                      onChange={(e) => {
                        updateData("media", key, e.target.value);
                      }}
                    />
                  </div>
                ))}
              </Tab>
            )}

            {/* {!!Object.entries(links).length && (
              <Tab eventKey="links" title="Links">
                {Object.entries(links).map(([key]) => (
                  <div key={key} className="test-component toolBox-properties">
                    <label>{cms.links[key]}:</label>
                    <input
                      type="text"
                      value={properties.links[key]}
                      onChange={(e) => {
                        updateData("links", key, e.target.value);
                      }}
                    />
                  </div>
                ))}
              </Tab>
            )} */}
            {nonCustomizable && (
              <Tab
                eventKey="disabled"
                tabClassName="w-100 px-0"
                disabled
                title={
                  <strong className="alert alert-warning mb-2 d-block">
                    No option available to customize
                  </strong>
                }
              ></Tab>
            )}
          </Tabs>
        </div>
      </>
    );
  };

  return (
    <aside id="sideBar" className={`${props.className} provider-sidebar`}>
      {Object.entries(properties).length && getProperties()}
    </aside>
  );
};

export default SideToolbar;
