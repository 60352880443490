import React, { useState } from "react";
import { Button } from "react-bootstrap";
import GrowSpinner from "../growSpinner";
import CommonModal from "./../commonModal";
import moment from "moment";
import baseHelper from "../../helper/baseHelper";
import "./common.css";

const BuilderSetting = (props) => {
  const [loader, setLoader] = useState({});
  const [show, setShow] = useState(false);

  const closeModel = () => setShow(false);

  const {
    dataStates,
    history,
    brandName,
    name,
    shop,
    toastProps,
    type,
  } = props;
  const { resetting, saving, publishing } = loader;

  const getButtonContent = (type) => {
    let content = "";
    switch (type) {
      case "reset": {
        content = "Reset Template";
        if (resetting) content = <GrowSpinner text={"Resetting..."} />;
        break;
      }
      case "chooseTemplate": {
        content = "Choose template";
        break;
      }
      case "saveDraft": {
        content = "Save as draft";
        if (saving) content = <GrowSpinner text={"Saving..."} />;
        break;
      }
      case "publish": {
        content = "Publish";
        if (publishing) content = <GrowSpinner text={"Publishing..."} />;
        break;
      }
      default: {
        break;
      }
    }
    return content;
  };

  const updateTemplate = async () => {
    try {
      setLoader((prev) => ({ ...prev, saving: true }));
      const newData = { ...dataStates.data, isPublished: false };
      dataStates.setData(newData);
      const response = await baseHelper.updateTemplate(newData);
      if (response.data.data.nModified) {
        toastProps.setToastState({
          toastBody: "Template saved successfully",
          active: true,
        });
      }
      setLoader((prev) => ({ ...prev, saving: false }));
    } catch (error) {
      console.log(error);
      setLoader((prev) => ({ ...prev, saving: false }));
      toastProps.setToastState({
        toastBody: "Something went wrong while saving",
        active: true,
      });
    }
  };

  const previewTemplate = () => {
    window.open(
      `${window.origin}/preview/${shop}/${brandName}/${localStorage.getItem(
        "token"
      )}`
    );
  };
  const publishTemplate = async () => {
    try {
      setLoader((prev) => ({ ...prev, publishing: true }));
      const newData = {
        ...dataStates.data,
        isPublished: true,
        publishedAt: moment(new Date())
          .format()
          .toString(),
      };
      const response = await baseHelper.updateTemplate(newData);
      dataStates.setData(newData);
      if (response.data.data.nModified) {
        toastProps.setToastState({
          toastBody: "Published template successfully",
          active: true,
        });
      }
    } catch (error) {
      toastProps.setToastState({
        toastBody: "Something went wrong",
        active: true,
      });
    }
    setLoader((prev) => ({ ...prev, publishing: false }));
  };

  const getBasicTemplate = async () => {
    try {
      setLoader((prev) => ({ ...prev, resetting: true }));
      await baseHelper.getOperatorTemplate(dataStates, { name, type }, shop);
      setLoader((prev) => ({ ...prev, resetting: false }));
      setShow(false);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="options-btn">
      <Button
        disabled={!dataStates.hasData}
        className="reset"
        onClick={() => setShow(true)}
      >
        {getButtonContent("reset")}
      </Button>
      <CommonModal
        cancelButton="Cancel"
        actionButton="Yes"
        show={show}
        getTemplate={getBasicTemplate}
        handleClose={closeModel}
        className="text-center p-4"
        top="40px"
      >
        Are you sure want to reset the template
      </CommonModal>
      <Button
        className="d-none choose-template"
        onClick={() =>
          history.push(`/createSite/${type}/${localStorage.getItem("token")}`)
        }
      >
        {getButtonContent("chooseTemplate")}
      </Button>

      <Button
        disabled={!dataStates.hasData}
        className="save"
        onClick={updateTemplate}
      >
        {getButtonContent("saveDraft")}
      </Button>

      <Button
        disabled={!dataStates.hasData}
        className="preview"
        onClick={previewTemplate}
      >
        {"Preview"}
      </Button>

      <Button
        disabled={!dataStates.hasData}
        variant="primary"
        className="publish"
        onClick={publishTemplate}
      >
        {getButtonContent("publish")}
      </Button>
    </div>
  );
};
export default BuilderSetting;
