import React, { useContext } from "react";
import { Container } from "react-bootstrap";
import "./oneColumn.css";
import BuilderContext from "../context/builderData";

const OneColumnTemplate = (props) => {
  const { data, dropZoneClass, renderComponent } = props;
  const { role } = useContext(BuilderContext);

  const marginBottom = (name) => name === "header" || name === "footer";

  const sectionComponents = (data) => {
    // const isVendor = role === "vendor";
    if (data[0].name === "header") {
      data.shift();
    }
    if (data[data.length - 1].name === "footer") {
      data.pop();
    }
    const sectionData = JSON.parse(JSON.stringify(data));
    const components = sectionData.map((component, index) => (
      <>
        <section
          id={`${component.name}_${index}`}
          className={dropZoneClass}
          style={{ marginBottom: marginBottom(component.name) ? 0 : 60 }}
        >
          {renderComponent(component.name, index, component)}
        </section>
        <section
          id={`empty_${index}`}
          className={`empty ${dropZoneClass}`}
        ></section>
      </>
    ));

    return components;
  };

  /* **NOTE** id in the header, footer and section tags is mandatory ****** */

  return (
    <Container fluid className="oneColumnLayout">
      {sectionComponents(data)}
    </Container>
  );
};
export default OneColumnTemplate;
