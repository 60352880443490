import React, { useState } from "react";
import { Button } from "react-bootstrap";
import GrowSpinner from "../growSpinner";
import baseHelper from "../../helper/baseHelper";
import CommonModal from "../commonModal";
import "./common.css";

const BuilderSetting = (props) => {
  const [loader, setLoader] = useState({});
  const {
    dataStates,
    brandName,
    slug,
    shop,
    toastProps,
    type,

    isBrandTemplateUpdated,
  } = props;
  const { resetting, updating } = loader;
  const [show, setShow] = useState(false);
  const [refresh, setRefresh] = useState(false);

  const closeModal = () => setShow(false);
  const refreshPage = () => setRefresh(true);

  const getButtonContent = (type) => {
    let content = "";
    switch (type) {
      case "reset": {
        content = "Confirm";
        if (resetting) content = <GrowSpinner text={"refreshing..."} />;
        break;
      }
      case "chooseTemplate": {
        content = "Choose template";
        break;
      }
      case "update": {
        content = "Update";
        if (updating) content = <GrowSpinner text={"Updating..."} />;
        break;
      }
      default: {
        break;
      }
    }
    return content;
  };

  const updateTemplate = async () => {
    try {
      setRefresh(false);
      setLoader((prev) => ({ ...prev, updating: true }));
      const newData = { ...dataStates.data };
      await baseHelper.updateVendorTemplate(newData, slug, brandName);
      dataStates.setData(newData);

      toastProps.setToastState({
        toastBody: "Template saved successfully",
        active: true,
      });
      setLoader((prev) => ({ ...prev, updating: false }));
    } catch (error) {
      console.log(error);
      setLoader((prev) => ({ ...prev, updating: false }));
      toastProps.setToastState({
        toastBody: "Something went wrong while  saving",
        active: true,
      });
    }
  };
  // Get new updated operator template
  const getOperatorTemplate = async () => {
    try {
      setLoader((prev) => ({ ...prev, resetting: true }));
      await baseHelper.getOperatorTemplate(dataStates, { shop, type }, shop);
      const newData = { ...dataStates.data };
      await baseHelper.updateVendorTemplate(newData, brandName);
      closeModal();
      refreshPage();
      setLoader((prev) => ({ ...prev, resetting: false }));
    } catch (error) {
      console.log(error);
    }
  };

  const previewTemplate = () => {
    window.open(
      `${window.origin}/preview/${shop}/${brandName}/${localStorage.getItem(
        "token"
      )}`
    );
  };

  return (
    <div className="options-btn">
      {isBrandTemplateUpdated && (
        <div className="template-alert-banner text-center p-2 fixed-top w-100">
          Your marketplace operator has updated the brand template please{" "}
          <span className="text-underline" onClick={() => setShow(true)}>
            Click here
          </span>{" "}
          to see the updated template
        </div>
      )}
      <CommonModal
        handleClose={closeModal}
        actionButton={getButtonContent("reset")}
        getTemplate={getOperatorTemplate}
        cancelButton="Cancel"
        show={show}
        top="75px"
      >
        Please click confirm to preview the latest template or cancel to stay on
        current template.
      </CommonModal>
      {!!refresh && (
        <Button onClick={() => window.location.reload(false)}>
          Revert to previous version
        </Button>
      )}
      <Button disabled={!dataStates.hasData} onClick={previewTemplate}>
        {"Preview"}
      </Button>
      <Button disabled={!dataStates.hasData} onClick={updateTemplate}>
        {getButtonContent("update")}
      </Button>
    </div>
  );
};
export default BuilderSetting;
