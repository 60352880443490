import CreateSite from "../pages/private/createSite";
import Builder from "../pages/private/builder";
import Preview from "../pages/private/preview";

// Note : -  commented code is useful for phase - 2 release

// import VerifyVendorTemplate from "../pages/private/verifyVendorTemplate";

const routes = [
  {
    path: "/builder/:token",
    component: Builder,
  },
  {
    path: "/preview/:shop/:brandName/:token",
    component: Preview,
  },
  {
    path: "/createSite/:type/:token",
    component: CreateSite,
  },
  // {
  //   path: "/verify/:vendorId/:token",
  //   component: VerifyVendorTemplate,
  // },
];

export default routes;
