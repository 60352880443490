import React from "react";
import { Form } from "react-bootstrap";

function TextField(props) {
  const { value, setValue } = props;

  return (
    <div className="d-flex text-field-wrapper align-items-center">
      <Form.Control
        className="style-text-field rounded-0"
        type="number"
        value={value}
        onChange={setValue}
        placeholder="value"
      />
      px
    </div>
  );
}

export default TextField;
