import baseHelper from "../../../lib/helper/baseHelper";
import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import Talk from "talkjs";
import "./style.css";

const VendorChat = (props) => {

  const { vendorData, shop, brandName: vendorShop } = props || {};

  const [show, setShow] = useState(false);
  const [talkCurrentUser, setTalkCurrentUser] = useState(false);
  const [chat, setChat] = useState(false);
  const [flag, setFlag] = useState(false);
  const handleShow = () => setShow(true);

  const [name, setName] = useState(null);
  const [userEmail, setUserEmail] = useState(null);

  const [userId, setUserId] = useState("");
  const [error, setError] = useState({
    email:null,
    name:null,
  });
  const handleClose = () => {
    setShow(false);
    setUserEmail(null);
    setName(null)
    setError({
      email:null,
      name:null,
    })
  }
  
  useEffect(() => {
    const query = window.location.search;
    const urlParams = new URLSearchParams(query);
    const converstationId = urlParams.get("id");
    const recipientId = urlParams.get("recipientId");
    const customerName = urlParams.get("name");
    if (recipientId) {
      setFlag(true);
    }
    Talk.ready.then(() => {
      const me = new Talk.User({
        id: recipientId,
        name: customerName,
        role: "customer",
      });
      window.talkSession = new Talk.Session({
        appId: "twLyn6PC",
        me,
      });
      setTalkCurrentUser(me);
      const popup = window.talkSession.createPopup(converstationId, {
        keepOpen: false,
      });
      popup.mount({ show: false });
      popup.show();
    });
  }, []);

  useEffect(() => {
    Talk.ready.then(() => {
      const me = new Talk.User({
        id: userId,
        name: name,
        role: "customer",
        email: userEmail,
        custom: {
          email: vendorData.basicInfo.email,
          shop,
          vendorShop
        }
      });

      window.talkSession = new Talk.Session({
        appId: "twLyn6PC",
        me,
      });
      setTalkCurrentUser(me);
    });
}, [name, userEmail, userId, vendorData.basicInfo.email, shop, vendorShop])

  const uuidv4 = () => {
    const crypto = window.crypto;
    const randomByte = crypto
      ? function() {
          return crypto.getRandomValues(new Uint8Array(1))[0];
        }
      : function() {
          return Math.floor(Math.random() * 255);
        };
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, function(c) {
      // eslint-disable-next-line no-mixed-operators
      return (c ^ (randomByte() & (15 >> (c / 4)))).toString(16);
    });
  };

  if (!userId) {
    setUserId(uuidv4());
  }

  const handleEmailValidation = (email) => {
    if (!baseHelper.validEmail(email)) {
      setError((prevState) => ({
        prevState,
        email: "Required field",
      }));
    }
    if (baseHelper.validEmail(email)) {
      setError((prevState) => ({
        prevState,
        email: "",
      }));
    }
  };

  const handleNameValidation =  (name) => {
  if(!baseHelper.validName(name)){
    setError(prevState=>({
      prevState,
      name:"Required field"
    }))
  }
    if(baseHelper.validName(name)){
      setError(prevState=>({
        prevState,
        name:""
      }))   
  }
  };

  const initiateChat = () => {
    if (!window.talkSession || !talkCurrentUser) {
      return false;
    }
    const other = new Talk.User({
      id: vendorData.basicInfo.id,
      name: `${vendorData.basicInfo.firstName} ${vendorData.basicInfo.lastName}`,
      email: vendorData.basicInfo.email,
      role: "vendor",
      custom: {
        email: userEmail,
      },
    });

    const conversation = window.talkSession.getOrCreateConversation(
      Talk.oneOnOneId(talkCurrentUser, other)
    );
    conversation.setParticipant(talkCurrentUser);
    conversation.setParticipant(other);

    const popup = window.talkSession.createPopup(conversation, {
      keepOpen: false,
    });
    popup.mount({ show: false });

    popup.show();
    setChat(true);
  };

  return (
    <>
      {!flag && !show && !chat ? (
        <button
          onClick={handleShow}
          className="fixed-bottom btn chat-button border-0 rounded"
        >
          <i class="fa fa-comments-o mr-2" aria-hidden="true"></i>
          Vendor Chat
        </button>
      ) : null}
      <Modal
        dialogClassName="custom-dialog fixed-bottom w-100"
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title className="h5">Customer Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form class="form-horizontal">
            <div class="form-group position-relative mb-3">
              <input
                type="text"
                class="form-control customized-input"
                placeholder="Name *"
                onChange={(e) => {
                  setName(e.target.value);
                }}
                onBlur = { () => {
                  handleNameValidation(name);
                 }}
              />
              <span className="error position-absolute">{(error && error.name) || null}</span>
            </div>
            <div class="form-group position-relative mb-0">
              <input
                type="email"
                class="form-control customized-input"
                placeholder="Email *"
                onChange={(e) => {
                  setUserEmail(e.target.value);
                }}
                onBlur={() => {
                  handleEmailValidation(userEmail);
                }}
              />
              <span className="error position-absolute">{(error && error.email) || null}</span>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn m-0"
            disabled={!baseHelper.validName(name) || !baseHelper.validEmail(userEmail)}
            onClick={() => [
              initiateChat(userId, name, userEmail),
              setShow(false),
            ]}
          >
            Initiate Chat
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default VendorChat;
