import React from "react";
import "./skeleton.css";

function Skeleton(props) {
  const { builder = true, header, subheader, sidebar = true } = props;
  const condition = {
    cHeader: header || builder ? true : false,
    cSubheader: subheader || builder ? true : false,
    cSidebar: sidebar,
  };

  const { cHeader, cSubheader, cSidebar } = condition;

  return (
    <main>
      {cHeader && (
        <h3 className="blog-post-headline">
          <span className="skeleton-box w-100"></span>
        </h3>
      )}
      {cSubheader && <span className="skeleton-box sub w-100"></span>}
      <div className="media d-flex">
        <div className="media-body w-100 p-5">
          <div>
            <span className="skeleton-box h80 w-100"></span>
            <span className="skeleton-box h200 w-100"></span>
            <span className="skeleton-box h80 w-100"></span>
            <span className="skeleton-box h200 w-100"></span>
          </div>
        </div>
        {cSidebar && (
          <div className="box w-100 p-5 pr-0">
            <span className="skeleton-box h-100 w-100"></span>
          </div>
        )}
      </div>
    </main>
  );
}

export default Skeleton;
