import React, { useState } from "react";
import reactCSS from "reactcss";
import { ChromePicker } from "react-color";

const ColorPalet = (props) => {
  const { updateData, keyProperty, property } = props;
  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const [color, setColor] = useState(`${props.color}`);

  const handleClick = () => {
    setDisplayColorPicker((prev) => !prev);
  };

  const handleClose = () => {
    setDisplayColorPicker(false);
  };

  const handleChange = (color) => {
    setColor(color.hex);
    updateData(property, keyProperty, color.hex);
  };

  const styles = reactCSS({
    default: {
      color: {
        width: "100px",
        height: "27px",
        background: color,
      },
      swatch: {
        padding: "3px",
        border: "1px solid #acacac",
        background: "#fff",
        borderRadius: "1px",
        cursor: "pointer",
      },
      popover: {
        position: "absolute",
        zIndex: "2",
      },
      cover: {
        position: "fixed",
        top: "0px",
        right: "0px",
        bottom: "0px",
        left: "0px",
      },
    },
  });

  return (
    <div className="color-palet">
      <div style={styles.swatch} onClick={handleClick}>
        <div style={styles.color} />
      </div>
      {displayColorPicker ? (
        <div style={styles.popover}>
          <div style={styles.cover} onClick={handleClose} />
          <ChromePicker color={color} onChange={handleChange} />
        </div>
      ) : null}
    </div>
  );
};

export default ColorPalet;
