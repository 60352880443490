import React from "react";
import { Form, Col } from "react-bootstrap";
const Select = (props) => {
  const { options, updateData, property, keyProperty } = props;
  const onOptionChange = (referece) => {
    referece.preventDefault();
    const { value } = referece.target;
    updateData(property, keyProperty, value);
  };
  return (
    <Form>
      <Form.Row className="align-items-center">
        <Col xs="auto">
          <Form.Label
            className="mr-sm-2"
            htmlFor="inlineFormCustomSelect"
            srOnly
          >
            Preference
          </Form.Label>
          <Form.Control
            as="select"
            className="mr-sm-2"
            id={keyProperty}
            onChange={onOptionChange}
            custom
          >
            {options.map((item) => (
              <option
                key={item.value}
                value={item.value}
                defaultValue={props.value === item.value}
              >
                {item.label}
              </option>
            ))}
          </Form.Control>
        </Col>
      </Form.Row>
    </Form>
  );
};

export default Select;
