import React, { useState } from "react";
import GetNamedComponent from "./getNamedComponent";
import OneColumnTemplate from "../oneColumnTemplate";
import { Alert } from "react-bootstrap";
import "./renderMachine.css";

export default function RenderMachine(props) {
  const {
    builderPage,
    history,
    match,
    pageName,
    builderClass = "",
    data = {},
    removeElement,
    dropZoneClass = "",
    setActiveComponentData,
    moveComponent,
  } = props;

  
  const [active, setActive] = useState({});

  /* 
    @desc   handleTemplateClick is method for select component 
    @params nameIndex  name and index of the component
    @param  componentData props of the component
*/

  const handleTemplateClick = (nameIndex, componentData) => {
    if (active[nameIndex]) {
      setActive({ none: "-1" });
      setActiveComponentData(false);
    } else {
      setActive({ [nameIndex]: "activeTemplate" });
      setActiveComponentData({
        index: parseInt(nameIndex.split("_")[1], 10),
        componentData,
        pageName,
      });
    }
  };

  /* @params name component name
     @params index component index
     @params componentData component all props (content , media)
     @return GetNamedComponent

*/

  const innerHTML = (name, index, componentData = {}) => {
    const nameIndex = `${name}_${index}`;
    const getNamedComponentProps = {
      props: componentData.props,
      name,
      history,
      match,
    };

    const { media, style } = componentData.cms;

    const nonCustomizable =
      !Object.entries(style).length && !Object.entries(media).length;

    const showup = () => {
      if (name === "header") return false;
      if (name === "footer") return false;
      if (index === 0) return false;
      if (data[0].name === "header" && index === 1) return false;
      return true;
    };
    const showdown = () => {
      if (name === "header") return false;
      if (name === "footer") return false;

      const dataLength = data.length - 1;
      if (data[dataLength].name === "footer" && index === dataLength - 1) {
        return false;
      }

      if (index === dataLength) {
        return false;
      }
      return true;
    };
    if (!builderPage) return <GetNamedComponent {...getNamedComponentProps} />;

    return (
      <div key={nameIndex} className={`${builderClass} ${active[nameIndex]}`}>
        <div className="edit-options">
          {showup() && (
            <button
              className="arrow up-btn"
              title="Move up"
              onClick={() => moveComponent(index, -1)}
            >
              <i className="fa fa-angle-up" aria-hidden="true"></i>
            </button>
          )}
          {showdown() && (
            <button
              className="arrow down-btn"
              title="Move down"
              onClick={() => moveComponent(index, 1)}
            >
              <i className="fa fa-angle-down" aria-hidden="true"></i>
            </button>
          )}
          <button
            className={`edit-btn`}
            disabled={nonCustomizable}
            onClick={() => handleTemplateClick(nameIndex, componentData)}
          >
            <i className="fa fa-cog" aria-hidden="true"></i>
          </button>

          <button
            className={`border-left remove-btn ${
              data.length <= 2 ? "disabled" : ""
            }`}
            disabled={data.length <= 2}
            onClick={() => removeElement(index)}
          >
            <i className="fa fa-trash" aria-hidden="true"></i>
          </button>
          <Alert
            className="tooltip-alert rounded-2 p-2 d-none text-center position-absolute"
            variant="warning"
          >
            Two sections are required in template
          </Alert>
        </div>

        <GetNamedComponent
          {...getNamedComponentProps}
          active={Object.keys(active)[0]}
          index={index}
        />
      </div>
    );
  };
  const renderComponent = (name, index, componentData) => {
    return innerHTML(name, index, componentData);
  };

  const layout = (
    <OneColumnTemplate
      data={data}
      dropZoneClass={dropZoneClass}
      renderComponent={renderComponent}
    />
  );
  const marginTop = builderPage ? "0px" : "0px";

  return (
    <div
      className="w-100 template"
      style={{ padding: 0, paddingTop: marginTop }}
    >
      {layout}
    </div>
  );
}
