import React, { useEffect, useState } from "react";
import { Container, Row, Spinner } from "react-bootstrap";
import CurrentUserContext from "../lib/components/context/builderData";
import helper from "../lib/helper/baseHelper";

const PublicLayout = (props) => {
  const { component: Component, route } = props;
  const [vendorData, setVendorData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const { shop, brandName } = route.match.params;

  const getPageData = async () => {
    const response = await helper.getVendorDetails(shop, brandName);
    setVendorData(response);
    setIsLoading(false);
  };

  const context = {
    ...props.route,
    vendorData: { ...vendorData },
  };

  useEffect(() => {
    getPageData();
  }, []);

  useEffect(() => {
    document.title = brandName;
  }, []);

  if (isLoading) {
    return (
      <div>
        <Spinner />
      </div>
    );
  }

  return (
    <CurrentUserContext.Provider value={context}>
      <Container fluid>
        <Row className="publicLayout">
          <Component {...props.route} />
        </Row>
      </Container>
    </CurrentUserContext.Provider>
  );
};

export default PublicLayout;
