import React, { useState, useContext } from "react";
import context from "../../../lib/components/context/builderData";
import GrowSpinner from "../../../lib/components/growSpinner";
import Toast from "../../../lib/components/toast";
import baseHelper from "../../../lib/helper/baseHelper";
import { Button } from "react-bootstrap";
import "./createSite.css";

const CreateSite = (props) => {
  const { shop } = useContext(context);
  const [creating, setCreating] = useState(false);
  const [active, setActive] = useState({ "-1": "activeTemplate" });
  const [toastState, setToastState] = useState({ active: false });

  const { token, type } = props.match.params;
  const handleTemplateClick = (templateNumber) => {
    setActive({ [templateNumber]: "activeTemplate" });
  };
  let chooseTemplate = "Choose a Template";
  const getButtonContent = () => {
    let content = "Create template";
    if (active["oneColumnLayout"]) content = "Create one column layout";
    if (creating) content = <GrowSpinner text={"Creating..."} />;
    return content;
  };
  const onClick = async () => {
    const activeTemplate = Object.keys(active)[0];

    if (activeTemplate !== "0" && activeTemplate !== "-1") {
      setCreating(true);
      const { data } = await baseHelper.fetchTemplate({
        type,
        name: activeTemplate,
      });

      const templateData = {
        data,
        name: activeTemplate,
        shop,
        type,
        sellerId: baseHelper.decodeToken()._id,
      };

      try {
        const response = await baseHelper.createTemplate(templateData);

        setCreating(false);
        if (!response.data.data) {
          setToastState({ active: true, toastBody: "Something went wrong" });
          return;
        }
        props.history.push(`/builder/${localStorage.getItem("token")}`);
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <div className="createWebpage h-100vh w-100">
      <Toast toastState={toastState} setToastState={setToastState} />
      <div className="container text-center">
        <h1 className>Create your page with the MARKETCUBE editor.</h1>
        <div className="des mb-5">
          Start with a template and make it your own, with easy drag and drop
          design features.
        </div>
        <button>{chooseTemplate}</button>
        <div className="webpageTemplates">
          <div
            className={active["oneColumnLayout"]}
            onClick={() => handleTemplateClick("oneColumnLayout")}
          >
            <p>one column layout</p>
          </div>
          <div className={active["2"]} onClick={() => handleTemplateClick(0)}>
            <p>Coming soon...</p>
          </div>
          <div className={active["3"]} onClick={() => handleTemplateClick(0)}>
            <p>Coming soon...</p>
          </div>
        </div>
        <div>
          <Button
            variant="secondary"
            onClick={() => {
              props.history.push(`/builder/${token}`);
            }}
          >
            Back to builder page
          </Button>
          <Button variant="primary" className="ml-5" onClick={() => onClick()}>
            {getButtonContent()}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default CreateSite;
